*,
*::after,
*::before {
  margin: 0;
  padding: 0 0;
  box-sizing: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  object-fit: inherit;
  text-shadow: inherit;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  display: block;
  overflow-y: visible;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 10px;
  border: 3px solid #fff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* body::-webkit-scrollbar {
  width: 0px !important;
} */

body:focus {
  outline: none;
}

div {
  outline-style: none;
}

input {
  position: relative;
  border: 0.1em solid #dee2e6;
  font-size: 1.6em;
  width: 100%;
  margin: 0;
  line-height: 1.6em;
  padding: 0;
  /* border-radius: 0.5em; */
  outline: none;
}

*:focus {
  outline: none;
  outline: 0;
}

input,
input:before,
input:after {
  -webkit-user-select: initial !important;
  -khtml-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  user-select: initial !important;
}

br {
  height: 1em !important;
  font-size: 1em !important;
  line-height: 0;
  margin: 0;
}

p {
  font-size: 1em;
  margin: 0;
  font-weight: 400;
  white-space: pre-wrap;
  word-break: break-word;
}

b {
  font-weight: 600;
}

ol {
  margin: 0;
  font-size: 1.2em;
  /* color: #18263b; */
}

ul {
  margin: 0;
  /* color: #18263b; */
}

li {
  font-size: 1em;
  /* color: #18263b; */
}

/* h1 are mainly used for page titles */
h1 {
  font-size: 3em;
  margin: 0 0 calc(1.6em / 3) 0;
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 500;
}

h2 {
  font-size: 2.4em;
  margin: 0 0 calc(1.6em / 2.4) 0;
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 400;
}

/* h3 are mainly used for labels */
h3 {
  font-size: 2em;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 300;
  margin: 0;
}

/* h4 are mainly used for sublabels */
h4 {
  font-size: 1.6em;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  margin: 0;
}

h5 {
  font-size: 1.4em;
  margin: 0 0 calc(1em / 1.4) 0;
}

h6 {
  font-size: 1em;
  margin: 0 0 calc(1em / 1) 0;
}

button {
  position: relative;
  font-size: 1.4em;
  font-weight: 400;
  margin: 0;
  color: #fbfbf2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 3em;
  border: none;
  padding: 0;
  text-transform: none;
  outline: none;
}

hr {
  /* border: 0; prevents tinytexteditor <hr> line element from showing*/
  height: 0;
  box-sizing: initial;
  overflow: visible;
}

small {
  font-size: smaller;
}

i {
  font-style: italic;
}

em {
  font-style: italic;
}

strong {
  font-weight: 600;
}

u {
  text-decoration: underline;
}

blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

@font-face {
  font-family: 'Magneta';
  src: url('./assets/fonts/Magneta-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Goldenbook';
  src: url('./assets/fonts/Goldenbook.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Kabel';
  src: url('./assets/fonts/Neue-Kabel.ttf') format('truetype');
}

@font-face {
  font-family: 'Songti SC';
  src: url('./assets/fonts/Songti-SC.ttf') format('truetype');
}

@font-face {
  font-family: 'Dunbar Low';
  src: url('./assets/fonts/Dunbar-Low.otf') format('opentype');
}

@font-face {
  font-family: 'Didot';
  src: url('./assets/fonts/Didot.ttf') format('truetype');
}

.fade-in {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
